<template lang="">
  <modal-padrao
    ref="modal-filtro-modelo"
    max-width="70%"
    :titulo="$t('modulos.instrumento.tabela.faixas')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar_modal')"
    @ok="associarFaixas"
  >
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :mostrar-acoes="false"
      filtro-geral
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina-atual="tabela.paginaAtual"
      paginacao-em-memoria
      sem-paginacao
      :filters="filters"
      ajustar-altura-linha
      :global-filters="tabela.colunas.map(c => c.value)"
    >
      <template v-slot:qtd="{ slotProps }">
        <div> {{ slotProps.data.modeloInstrumentoFaixaPonto.length }}</div>
      </template>
    </tabela-padrao-prime-vue>
  </modal-padrao>
</template>
<script>
import ModeloInstrumentoService from '@common/services/cadastros/ModeloInstrumentoService.js';
import { FilterMatchMode } from 'primevue/api';
import helpers from '@common/utils/helpers';
import _ from 'lodash';
export default {
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'descricao',
            text: this.$t('modulos.instrumento.tabela.faixasTable.faixa'),
            sortable: true,
            width: 400,
            // formatter: (valor, linha) => {
            //   if (linha && linha.unidadeMedida)
            //     return `(${helpers.formatarNumeroComPrecisao(
            //       linha.amplitudeMinima
            //     )} a ${helpers.formatarNumeroComPrecisao(
            //       linha.amplitudeMaxima
            //     )}) ${linha.unidadeMedida.simbolo} ; ${linha.resolucao}`;
            // },
          },
          {
            value: 'amplitudeMinima',
            text: this.$t(
              'modulos.tipo_instrumento.tabela.faixas.amplitude_minima'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'amplitudeMaxima',
            text: this.$t(
              'modulos.tipo_instrumento.tabela.faixas.amplitude_maxima'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'unidadeMedida',
            text: this.$t(
              'modulos.tipo_instrumento.tabela.faixas.unidade_medida'
            ),
            sortable: true,
            formatter: (v) => `${v?.nome} (${v?.simbolo})`,
          },
          {
            value: 'resolucao',
            text: this.$t(
              'modulos.modelo_instrumento.tabela.faixasTable.resolucao'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'qtd',
            text: this.$t(
              'modulos.instrumento.tabela.faixasTable.qtd_pontos_calibracao'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      faixas: [],
      referenciaForm: null,
    };
  },
  methods: {
    abrirModal: async function (form) {
      this.referenciaForm = form;
      await this.listarFaixas(form.modeloInstrumento.value);

      this.itens = _.cloneDeep(this.referenciaForm.instrumentoFaixa);
      this.$refs['modal-filtro-modelo'].abrirModal();
    },
    listarFaixas: async function (id) {
      await ModeloInstrumentoService.buscar(id).then((res) => {
        this.tabela.selecionados = [];
        this.tabela.dados = res.data.faixas;
        this.faixas = res.data.faixas;
        this.tabela.quantidadeItens = res.data.totalItems;
        this.tabela.quantidadePaginas = res.data.totalPages;
        this.tabela.paginaAtual = this.paginaAtual;
        this.tabela.porPagina = this.porPagina;
        this.referenciaForm.instrumentoFaixa.forEach(i => {
          const itemSelecionado = this.tabela.dados.find(rf => rf.descricao == i.descricao)
          if (itemSelecionado) this.tabela.selecionados.push(itemSelecionado)
        })
      });
    },
    associarFaixas() {
      this.tabela.selecionados.forEach(faixa => {
        const verificaFaixaExistente = this.referenciaForm.instrumentoFaixa.find(item => {
          return item.descricao == faixa.descricao;
        })
        if (verificaFaixaExistente) return;
        this.referenciaForm.instrumentoFaixa.push(faixa);
      });
      this.$emit('atualizarTabela');
      this.$refs['modal-filtro-modelo'].fecharModal();
    },
  },
};
</script>
