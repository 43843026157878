import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
import helpers from '../../utils/helpers';
export class InstrumentoModel {
  constructor({
    id,
    flagPadrao = false,
    participanteCliente,
    setor,
    setorId,
    codigoTag,
    numeroSerie,
    tipoInstrumento,
    modeloInstrumento,
    marca,
    proximaCalibracao,
    frequenciaCalibracaoEmMeses,
    proximaVerificacao,
    frequenciaVerificacaoEmMeses,
    participanteFornecedorCalibracaoId,
    valorInstrumento,
    dataAquisicao,
    flagRevisadoPelaUnidadeNegocioMantenedora = false,
    observacao,
    flagAtivo = false,
    tipoMaterial,
    dataUltimaCalibracao,
    instrumentoAnexo,
    instrumentoImagem,
    instrumentoFaixa,
    camposAdicionais = [],
    flagUtilizaLeitorOCR = false,
    flagVerificacaoIntermediaria = false,
    dataUltimaVerificacao,
  }) {
    this.id = id;
    this.flagPadrao = flagPadrao;
    if (participanteCliente)
      this.participanteCliente = new DropdownModel(participanteCliente);
    if (setor) this.setor = new DropdownModel(setor);
    this.setorId = setorId;
    this.codigoTag = codigoTag;
    this.numeroSerie = numeroSerie;
    this.tipoInstrumento = tipoInstrumento
      ? new DropdownModel(tipoInstrumento)
      : null;
    this.marca = marca ? new DropdownModel(marca) : null;
    if (modeloInstrumento)
      this.modeloInstrumento = new DropdownModel(modeloInstrumento);
    this.proximaCalibracao =
      helpers.converterDataParaIsoString(proximaCalibracao);
    this.frequenciaCalibracaoEmMeses = frequenciaCalibracaoEmMeses;
    this.proximaVerificacao =
      helpers.converterDataParaIsoString(proximaVerificacao);
    this.frequenciaVerificacaoEmMeses = frequenciaVerificacaoEmMeses;
    this.participanteFornecedorCalibracaoId =
      participanteFornecedorCalibracaoId;
    this.valorInstrumento = valorInstrumento;
    this.dataAquisicao = helpers.converterDataParaIsoString(dataAquisicao);
    this.flagRevisadoPelaUnidadeNegocioMantenedora =
      flagRevisadoPelaUnidadeNegocioMantenedora;
    this.observacao = observacao;
    this.flagAtivo = flagAtivo;
    this.tipoMaterial = tipoMaterial;
    this.dataUltimaCalibracao = this.instrumentoAnexo = instrumentoAnexo;
    this.instrumentoImagem = instrumentoImagem;
    this.instrumentoFaixa = instrumentoFaixa ? instrumentoFaixa : [];
    this.flagUtilizaLeitorOCR = flagUtilizaLeitorOCR;
    this.dataUltimaCalibracao =
      helpers.converterDataParaIsoString(dataUltimaCalibracao);
    this.flagVerificacaoIntermediaria = flagVerificacaoIntermediaria;
    this.dataUltimaVerificacao = helpers.converterDataParaIsoString(
      dataUltimaVerificacao
    );

    this.instrumentoFaixa.forEach((faixa) => {
      if (faixa.id) faixa.flagJaExistente = true;
      faixa.unidadeMedidaId = faixa.unidadeMedida.id;
    });

    if (camposAdicionais)
      this.camposAdicionais = _.uniqBy(
        camposAdicionais,
        'campoAdicional.id'
      ).map((el) => {
        let valorInput = el.valor;
        if (el.tipoCampo == 'ListaMultiplaEscolha') {
          if (valorInput.includes(',')) {
            valorInput = valorInput.split(',');
          } else {
            valorInput = [valorInput];
          }
        }
        return {
          value: el.campoAdicional.id,
          text: el.campoAdicional.nome,
          valorInput: valorInput,
        };
      });
  }

  get request() {
    const retorno = _.cloneDeep(this);
    retorno.setorId = retorno.setor?.value;
    retorno.marcaId = retorno.marca?.value;
    retorno.tipoInstrumentoId = retorno.tipoInstrumento?.value;
    retorno.modeloInstrumentoId = retorno.modeloInstrumento?.value;
    retorno.participanteClienteId = retorno.participanteCliente?.value;
    retorno.instrumentoFaixa.forEach((faixa) => {
      faixa.pontos?.forEach((ponto) => {
        if (!ponto.flagJaExistente) ponto.id = null;
      });

      if (!faixa.modeloInstrumentoFaixaId) {
        faixa.modeloInstrumentoFaixaId = faixa?.id;
      }
      if (!faixa.flagJaExistente) faixa.id = null;
    });
    retorno.camposAdicionais = [];

    retorno.camposAdicionais = this.camposAdicionais.reduce(
      (result, { value, valorInput, tipoCampo, edicaoValor }) => {
        if (!valorInput) return result;
        let valor;
        if (tipoCampo == 'Data') {
          valor = valorInput.substr(0, 10);
        } else if (Array.isArray(valorInput)) {
          valor = valorInput.join(',');
        } else {
          valor = valorInput;
        }

        if (edicaoValor == 'SomenteModelo') {
          valor = null;
        }

        result.push({
          campoAdicionalId: value,
          valor: valor,
        });
        return result;
      },
      []
    );
    return retorno;
  }
}
