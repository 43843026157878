<template lang="">
  <modal-padrao
    ref="modal-associar-pontos"
    max-width="50%"
    :titulo="$t('modulos.instrumento.botoes.selecionar_pontos')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    :ok-desabilitado="!tabela.selecionados[0]"
    @ok="associarPontos"
  >
    <div
      class="d-flex justify-space-between align-end
      "
    >
      <input-text 
        v-model="filtroAplicado.nome"
        class="w-90"
        :label="$t('modulos.modelo_instrumento.tabela.pontos.descricao')"
        @keydown.enter="listarTiposInstrumentos"
      />
      <botao-padrao
        color="secundary"
        outlined
        blocked
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="10"
      :quantidade-itens="tabela.quantidadeItens"
      :quantidade-paginas="tabela.quantidadePaginas"
    />
  </modal-padrao>
</template>
<script>
import ModeloInstrumentoService from '@common/services/cadastros/ModeloInstrumentoService.js'
import helpers from '@common/utils/helpers'

export default {
  data() {
    return {
      flagPontosDuplicados: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'valor',
            text: this.$t('modulos.modelo_instrumento.tabela.pontos.valor'),
            sortable: true,
            formatter: v => helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'descricao',
            text: this.$t('modulos.modelo_instrumento.tabela.pontos.descricao'),
            sortable: true,
          },
          {
            value: 'tipoPonto',
            text: this.$t('modulos.modelo_instrumento.tabela.pontos.tipoPonto'),
            sortable: true,
            formatter: v => helpers.TipoPontoEnum.find(el => el.value == v)?.text
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        porPagina: 10,
      },
      filtroAplicado: {
        nome: ""
      },
      referenciaFaixa: null
    };
  },
  methods: {
    abrirModal: function (modeloInstrumentoId, faixaSelecionada, flagPontosDuplicados) {
      this.flagPontosDuplicados = flagPontosDuplicados;
      this.buscarModeloInstrumento(modeloInstrumentoId)
      this.referenciaFaixa = faixaSelecionada
      this.$refs['modal-associar-pontos'].abrirModal();
    },
    buscarModeloInstrumento: function (id) {
      ModeloInstrumentoService.buscar(id)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.faixas
            .find(el => el.descricao == this.referenciaFaixa.descricao && el.resolucao == this.referenciaFaixa.resolucao)
              ?.modeloInstrumentoFaixaPonto;
          this.tabela.quantidadeItens = this.tabela.dados?.length
          this.tabela.quantidadePaginas = Math.ceil(this.tabela.quantidadeItens / this.tabela.porPagina) || 0
        }) 
    },
    associarPontos(){
      this.tabela.selecionados.forEach(ponto => {
        const pontos = this.referenciaFaixa.pontosCalibracao || this.referenciaFaixa.pontos;
        if (this.flagPontosDuplicados == false) {
          const pontoExistente = pontos
            .find(el => el.valor == ponto.valor && el.tipoPonto == ponto.tipoPonto)
          if (pontoExistente) return;
        }
        pontos.push(ponto)
      })
      this.$emit('atualizarTabela')
      this.$refs['modal-associar-pontos'].fecharModal()
    }
  },
}
</script>
