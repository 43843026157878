<!-- eslint-disable vue/attribute-hyphenation -->
<template lang="">
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        ref="input-valor"
        v-model="form.valor"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.valor')"
        :regras-personalizadas="regraValidacaoValor()"
        obrigatorio
        type="number"
      />
      <input-select
        v-model="form.tipoPonto"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.tipo_ponto')"
        obrigatorio
        :options="opcoes.tipoPonto"
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12 col-md-12"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.descricao')"
        :max="200"
      />
    </v-form>
  </template>
    <script>
    import helpers from '@/common/utils/helpers.js';
    export default {
      props: {
        tipoInstrumento: {}
      },
      data() {
        return {
          opcoes: {
            tipoPonto: helpers.TipoPontoEnum,
          },
          edicao: false,
          faixaSelecionada: null,
          form: {},
          pontoReferencia: {},
        };
      },
      computed: {
        EhTelaPequena(){
          return ['sm','xs'].includes(this.$vuetify.breakpoint.name)
        }
      },
      methods: {
        editarPonto(ponto, faixa){
          this.faixaSelecionada = faixa;
          this.pontoReferencia = ponto;
          this.form = {...ponto};
          this.edicao = true;
        },
        novoPonto(faixa){
          this.faixaSelecionada = faixa;
          this.iniciarForm();
        },
        iniciarForm() {
          this.form = {
            id: helpers.gerarNumeroAleatorio(),
            valor: null,
            descricao: null,
            tipoPonto: this.form.tipoPonto,
            origemPonto: helpers.OrigemPontoEnum.find(
              (el) => el.text == 'Instrumento'
            )?.value,
          };
          this.$refs.form?.resetValidation();
          this.edicao = false;
        },
        verificarExistente(valorPonto){
          if(!this.faixaSelecionada) return true;
          return this.faixaSelecionada.pontos
            .some(ponto => ponto.valor == valorPonto &&
               this.form.id != ponto.id && ponto.tipoPonto == this.form.tipoPonto)
        },
        regraValidacaoValor() {
          if(!this.faixaSelecionada) return [];
          const regraValor = (valor) => {
            if (this.formTipoInstrumento.flagPontosDuplicados == false) {
              if (this.verificarExistente(valor))
                return this.$t('modulos.tipo_instrumento.validacoes.ponto_existente')
            }

            if(this.form.tipoPonto === 'Percentual'){
              if (valor < 0 || valor > 100)
                return this.$t(
                  'modulos.tipo_instrumento.validacoes.valor_percentual'
                );
              return true;
            }
    
            if (valor < this.faixaSelecionada.amplitudeMinima || valor > this.faixaSelecionada.amplitudeMaxima){
              return this.$t(
                'modulos.tipo_instrumento.validacoes.valor'
              );
            }
            else return true;
          };
          return [regraValor];
        },
        salvar_continuar() {
          if (!this.$refs.form.validate()) return false;
          if (this.edicao) {
            Object.entries(this.form).forEach((value) => {
              this.pontoReferencia[value[0]] = value[1];
            });
          }
          if (!this.edicao) this.faixaSelecionada?.pontos.push(this.form);
          this.iniciarForm();
          this.$refs['input-valor']?.focus();
          return true
        },
      },
    };
    </script>
    