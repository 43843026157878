<template lang="">
  <div>
    <div v-if="!isOnlyView" class="d-flex align-center justify-space-between mb-3">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          outlined
          color="danger"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao @click="abrirNovo">
        {{ $t('modulos.instrumento.botoes.selecionar_faixa') }}
      </botao-padrao>
    </div>
    <!-- <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="filteredColunas"
      :por-pagina="tabela.porPagina"
      :mostrarCheckbox="!isOnlyView"
      sem-paginacao
      sem-acoes
    >
      <template
        v-slot:item.acoes="{ item }"
      >
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            @click="abrirPontosCalibracao(item.item)"
          >
            {{ $t('modulos.tipo_instrumento.tabela.pontos_de_calibracao') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao> -->
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :mostrar-acoes="false"
      filtro-geral
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina-atual="tabela.paginaAtual"
      paginacao-em-memoria
      sem-paginacao
      :filters="filters"
      ajustar-altura-linha
      :global-filters="tabela.colunas.map(c => c.value)"
    >
      <template v-slot:qtd="{ slotProps }">
        <div> {{ slotProps.data.pontos.length }}</div>
      </template>
      
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            @click="abrirPontosCalibracao(slotProps.data)"
          >
            {{ $t('modulos.tipo_instrumento.tabela.pontos_de_calibracao') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <FaixaModal
      ref="modal-faixa"
      @atualizarTabela="atualizarTabela"
    />
    <ModalPontosCalibracao
      ref="modal-pontos"
      :form="form"
      :instrumento-id="form.id"
    />
  </div>
</template>
<script>
import FaixaModal from './modais/FaixaModal';
import helpers from '@common/utils/helpers';
import { FilterMatchMode } from 'primevue/api';
import ModalPontosCalibracao from './modais/ModalPontosCalibracao';
export default {
  components: { FaixaModal, ModalPontosCalibracao },
  props: {
    form: {},
    isOnlyView: { type: Boolean, default: false },
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            sortable: false,
            width: 10,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.instrumento.tabela.faixasTable.faixa'),
            sortable: true,
            // formatter: (valor, linha) => {
            //   if (linha && linha.unidadeMedida)
            //     return `(${helpers.formatarNumeroComPrecisao(
            //       linha.amplitudeMinima
            //     )} a ${helpers.formatarNumeroComPrecisao(
            //       linha.amplitudeMaxima
            //     )}) ${linha.unidadeMedida.simbolo} ; ${linha.resolucao}`;
            // },
          },
          {
            value: 'amplitudeMinima',
            text: this.$t('modulos.tipo_instrumento.tabela.faixas.amplitude_minima'),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'amplitudeMaxima',
            text: this.$t('modulos.tipo_instrumento.tabela.faixas.amplitude_maxima'),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'unidadeMedida',
            text: this.$t('modulos.tipo_instrumento.tabela.faixas.unidade_medida'),
            sortable: true,
            formatter: v => `${v?.nome} (${v?.simbolo})`
          },
          {
            value: 'resolucao',
            text: this.$t('modulos.instrumento.tabela.faixasTable.resolucao'),
            sortable: true,
            formatter: (v) => helpers.formatarNumeroComPrecisao(v),
          },
          {
            value: 'qtd',
            text: this.$t('modulos.tipo_instrumento.tabela.faixas.quantidade_pontos'),
            sortable: true,
          },
        ],
        selecionados: [],
      },
      faixasAlteradas: false,
    };
  },
  computed: {
    filteredColunas() {
      if (this.isOnlyView) {
        return this.tabela.colunas.filter(coluna => coluna.value !== 'acoes');
      }
      return this.tabela.colunas;
    },
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
    tooltipPontos(){
      if(!!this.form.id && this.faixasAlteradas) return this.$t('modulos.instrumento.validacoes.pontos_faixas_alteradas')
      return (!this.form.id || "") && this.$t('modulos.instrumento.validacoes.pontos_somente_na_edicao')
    }
  },
  mounted() {
    this.tabela.dados = this.form.instrumentoFaixa;
  },
  methods: {
    abrirNovo() {
      if (!this.form.modeloInstrumento) {
        this.toastErro(
          this.$t(`modulos.instrumento.validacoes.escolher_modelo_instrumento`)
        );
        return;
      }
      this.$refs['modal-faixa'].abrirModal(this.form);
    },
    abrirEditar(faixa) {
      this.$refs['modal-faixa'].abrirModal(faixa);
    },
    abrirPontosCalibracao(faixa) {
      this.$refs['modal-pontos'].abrirModal(faixa);
    },
    atualizarTabela() {
      this.tabela.selecionados = []
      this.faixasAlteradas = true;
      this.tabela.dados = this.form.instrumentoFaixa;
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.instrumentoFaixa.splice(
            this.form.instrumentoFaixa.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
        this.faixasAlteradas = true;
      });
    },
  },
};
</script>
